/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import * as moment from 'moment-timezone';

//// CONTROLLER ////
export class TCDialogController {
    private sessionData: any;

    //Bindable
    public orderItemAssetId: any;
    public delivery: any;

    static get $inject() {
        return [
            'loginService',
            '$mdDialog',
            'DeliveryConfirmationResourceFactory',
            'DeliveryResourceFactory',
            'NotificationService',
            '$q',
            'assetName',
            'terms',
            'deliveredSpot',
            'originalSpot',
            'qtProxy',
            'deliveryId',
            'assetType',
        ];
    }

    constructor(
        public loginService: any,
        public $mdDialog: any,
        public DeliveryConfirmationResourceFactory: any,
        public DeliveryResourceFactory: any,
        public NotificationService: any,
        public $q: any,
        public assetName: any,
        public terms: any,
        public deliveredSpot: any,
        public originalSpot: any,
        public qtProxy: any,
        public deliveryId: any,
        public assetType: string
    ) {
        /*
        * This is the `vm` object. It is a direct reference to the controller
        * which acts as our 'view-model' in angular. It also limits our need
        * to be accessing $scope directly.
        */
        /*jshint validthis:true*/
        var vm = this;

        /* PRIVATE : DATA */
        //Declare all private variables here
        vm.sessionData = loginService.getSessionData();

        /* BINDABLE : DATA */
        vm.orderItemAssetId = [];
    }

    /* IMPLEMENTATION : BINDABLE */
    accept() {
        let vm = this;

        var currentDate = moment
            .tz(new Date(), moment.tz.guess())
            .format('MM/DD/YYYY h:mm A Z');

        vm.$q.all(
            // eslint-disable-next-line
            vm.orderItemAssetId.map(function iterator(cur:any, index:any, arr:any) {
                if (cur) {
                    return vm.DeliveryConfirmationResourceFactory.create(
                        {
                            id: vm.sessionData.id,
                            confirmationType: 'DOWNLOAD',
                            orderItemAssetId: cur,
                            confirmationDate: currentDate,
                        },
                        function success() {},
                        function failure(err:any) {
                            vm.NotificationService.showNotificationToast(
                                'Error accepting T&C.  Please try again.',
                                err
                            );
                        }
                    ).$promise;
                }
            })
        ).then(function () {
            vm.$mdDialog.hide({ accept: true });
        });
    }

    decline() {
        let vm = this;

        vm.$mdDialog.cancel();
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    _getDelivery() {
        let vm = this;

        // retrieve the assets for this delivery (because the grid cannot load them without getting bad artifacts (blank rows))
        vm.DeliveryResourceFactory.get(
            { id: vm.deliveryId },
            function success(ret:any) {
                vm.delivery = ret.data;

                vm.orderItemAssetId.push(vm._orderAssetIdGenerator(vm.deliveredSpot, 'spot'));
                vm.orderItemAssetId.push(vm._orderAssetIdGenerator(vm.originalSpot, 'original'));
                vm.orderItemAssetId.push(vm._orderAssetIdGenerator(vm.qtProxy, 'qt'));
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    }

    _orderAssetIdGenerator(assetId:any, type:any) {
        let vm = this;

        if (assetId === true) {
            var spotAssetId;
            var originalAssetId;
            var proxyAssetId;
            for (let i = 0; i < vm.delivery.Assets.length; i++) {
                if (vm.delivery.Assets[i].assetType === 'ORIGINAL') {
                    originalAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
                if (vm.delivery.Assets[i].assetType === 'SPOT') {
                    spotAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
                if (vm.delivery.Assets[i].assetType === 'PROXY') {
                    proxyAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
            }

            switch (type) {
                case 'spot':
                    return spotAssetId;
                case 'original':
                    return originalAssetId;
                case 'qt':
                    return proxyAssetId;
            }
        } else {
            return assetId;
        }
    }

    $onInit() {
        let vm = this;
        
        vm._getDelivery();
    }
}
